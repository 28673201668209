// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2bp5g{cursor:pointer;display:inline-block;width:40px;height:40px;margin-left:15px}", ""]);
// Exports
exports.locals = {
	"avatarContainer": "_2bp5g"
};
module.exports = exports;
