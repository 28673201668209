// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2gcJx{margin:20px 0 0}.dUUOb,._2gcJx a{text-decoration:none}._1yhl2{margin-left:15px}", ""]);
// Exports
exports.locals = {
	"root": "_2gcJx",
	"a": "dUUOb",
	"NavigationButton": "_1yhl2"
};
module.exports = exports;
