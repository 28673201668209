import s from './HeadMessage.css';
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';

export default function HeadMessage({ children, variant }) {
    useStyles(s);

    return (
        <div className={s.Head}>{children}</div>
    );
}