import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import s from './Header.css';
import Link from '../Link';
import Navigation from '../Navigation';
import logoUrl from './logo-small.png';
import logoUrl2x from './logo-small@2x.png';
import Container from "@material-ui/core/Container";
import {GridList} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

/**
 * Шапка (лого + меню)
 * @returns {*}
 * @constructor
 */
export default function Header() {
  useStyles(s);

  return (
    <div className={s.root}>
      <div className={s.headLine}>
        <div className={s.blue} />
        <div className={s.purple} />
        <div className={s.orange} />
      </div>

      <Container>
        <Grid container>
          <Grid item xs={3}>
            <Link className={s.brand} to="/">
              <img
                src={'/logo.png'}
                width={'202px'}
                alt="New Tab Studio"
              />
            </Link>
          </Grid>

          <Grid item xs={9} style={{ textAlign: 'right' }}>
            <Navigation />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
