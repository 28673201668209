// Навигационная кнопка
import Link from "../Link";
import Button from "@material-ui/core/Button";
import s from "./Navigation.css";
import React from "react";

export default function NavLink({ show = true, href, children, variant, color }) {
    if (show) {
        return (
            <Link to={href} className={s.a}>
                <Button className={s.NavigationButton} variant={variant} color={color}>
                    {children}
                </Button>
            </Link>
        )
    }

    return null;
}