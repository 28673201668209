import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import s from './UserMenu.css';
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Link from "../../Link";

/**
 * Аватарка и меню пользователя
 * @returns {*}
 * @constructor
 * @return {null}
 */
export default function UserMenu({ user }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    useStyles(s);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Получить первую букву для аватара
    function firstLetter(email) {
        if (typeof email === 'string' && email.length > 0) {
            return email.substr(0, 1).toUpperCase();
        } else {
            return 'A';
        }
    }

    if (user) {
        return (
            <span>
                <div className={s.avatarContainer}>
                    <Avatar component={'span'} onClick={handleClick}>{firstLetter(user.email)}</Avatar>
                </div>

                <Menu
                    id="user-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {user.role === 'admin' && <Link to={'/themes/all'}><MenuItem>All themes</MenuItem></Link>}
                    <MenuItem onClick={() => { window.open('/logout', '_self'); }}>Logout</MenuItem>
                </Menu>
            </span>
        );
    }

    return null;
}
