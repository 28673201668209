// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1IAZv{margin:40px 20px}._1IAZv h1,._1IAZv h2,._1IAZv h3{text-align:center;font-size:85px;margin:0}._1IAZv h2{font-size:70px}._1IAZv h3{font-size:60px}._1IAZv h5{text-align:center;font-size:15px}._1IAZv h1 span,._1IAZv h2 span,._1IAZv h3 span{color:var(--color-secondary)}", ""]);
// Exports
exports.locals = {
	"Head": "_1IAZv"
};
module.exports = exports;
