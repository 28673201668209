import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import s from './Navigation.css';
import Link from '../Link';
import ApplicationContext from '../ApplicationContext';
import getUser from '../../utils/getUser';
import Button from "@material-ui/core/Button";
import StarIcon from '@material-ui/icons/Star';
import UserMenu from "./UserMenu/UserMenu";
import NavLink from "./NavLink";

/**
 * Верхнее меню
 * @returns {*}
 * @constructor
 */
export default function Navigation() {
    useStyles(s);

    // Получить первую букву для аватара
    function firstLetter(email) {
        if (typeof email === 'string' && email.length > 0) {
            return email.substr(0, 1).toUpperCase();
        } else {
            return 'A';
        }
    }

    // Отрендерить меню для пользователя
    function renderUser(user) {
        return (
            <div>
                <div className={s.root} role="navigation">
                    <NavLink show={true} href={'/themes'}>User themes</NavLink>
                    <NavLink show={user} href={'/themes/my'}>My themes</NavLink>
                    <NavLink show={true} href={'/support'}>Support</NavLink>
                    <NavLink show={true} href={'/invite'}>Invite friends</NavLink>
                    {<NavLink href={'/pro'} color={'secondary'} variant={'contained'}><StarIcon style={{fontSize: 16}}/>&nbsp;PRO version</NavLink>}

                    {!user &&
                    <Button className={s.NavigationButton} href={'/login/google'} color={'primary'} variant={'contained'}>Sign in</Button>
                    }

                    { user && false && <Button className={s.NavigationButton} href={'/logout'} color={'primary'} variant={'contained'}>Logout</Button>}
                    <UserMenu user={user}/>
                </div>
            </div>
        );
    }

    return (
        <ApplicationContext.Consumer>
            {({context}) => (
                <div>
                    {renderUser(getUser(context))}
                </div>
            )}
        </ApplicationContext.Consumer>
    );
}
