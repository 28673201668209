// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--font-family-base:Rubik,\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1000px;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px;--color-secondary:#fa9300;--brand-color:#191a1b}.O9oW9{color:#191a1b}.qQ2mF{margin:0 auto;padding:20px 0;max-width:1000px;max-width:var(--max-content-width)}._2MnzC{padding-bottom:25px}._2MnzC div{height:5px;width:33.3333%;float:left}._1hO08{background:#1ec3f8}._2i4mo{background:#9a53cf}._1e3KN{background:#ff9e00}", ""]);
// Exports
exports.locals = {
	"root": "O9oW9",
	"container": "qQ2mF",
	"headLine": "_2MnzC",
	"blue": "_1hO08",
	"purple": "_2i4mo",
	"orange": "_1e3KN"
};
module.exports = exports;
